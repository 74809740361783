import AFFSidebarLayout from "@components/domain/AFFSidebarLayout"
import { useRouter } from "next/router"
import { useEffect } from "react"

/**
 * HomePage component is a placeholder for showing marketing-related info brochure.
 *
 * Roadmap
 * - 0.x : Empty placeholder that shouldn't get called. Instead, links to "home" should link to Wix or Webflow
 * - 1.x : Non-priority
 * - 2.x : Pull web content from WYSIWYG Web editor (eg, Webflow) and display it here, eg https://dev.to/kennedyrose/integrating-webflow-and-next-js-39kk
 */
function HomePage() {
    const router = useRouter()

    // Temporarily navigate anyone landing here to Live Schedule
    useEffect(() => {
        if (router.isReady) {
            router.replace("/classes")
        }
    }, [router])

    return (
        <div className="p-8">
        </div>
    )
}

HomePage.Layout = AFFSidebarLayout

export default HomePage
